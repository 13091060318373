import "@fontsource/noto-serif-jp"
import "./src/styles/foundation/variable.css"
import "./src/styles/foundation/normalize.css"
import "./src/styles/foundation/base.css"
import "./src/styles/foundation/blog-contents-style.css"

// ここを修正
import SmoothScroll from 'smooth-scroll';

export const onInitialClientRender = () => {
  new SmoothScroll('a[href*="#"]', {
    speed: 800,
    speedAsDuration: true,
  });
};
